<template>
  <div>
    <v-dialog width="80%" v-model="dialog" scrollable v-if="dialog">
      <v-card  class="invoice-show">
        <v-divider />

        <v-card-text class="pa-10">
          <v-row>
            <v-img src="~@images/logo.svg" max-width="250" contain />
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-row dense>
                <v-col   class="invoice-number text-right">
                  {{ $t('invoice.invoice-number', {number: invoice.invoiceReference}) }}
                </v-col>
              </v-row>
              <v-row dense v-if="currentUser.isZE && invoice.sageInvoiceNumber">
                <v-col class="sage-invoice-number text-right">
                  {{ $t('invoice.sage-invoice-number-header', {number: invoice.sageInvoiceNumber}) }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mt-10">
            <v-col cols="auto" class="billing-request-address">
              <b class="name">{{ invoice.issuerName }}</b>
              <div v-if="invoice.issuerAddress" v-html="formatAddress(invoice.issuerAddress)"></div>
              <div>{{ $t('account-number') }} : <b>{{ invoice.issuerAccountNumber }}</b></div>
              <div v-if="invoice.issuerRegistrationNumber">{{ $t('organization.registration-number') }} : <b>{{ invoice.issuerRegistrationNumber }}</b></div>
              <div v-if="invoice.issuerVatNumber">{{ $t('vat-number') }} : <b>{{ invoice.issuerVatNumber }}</b></div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="invoice-address">
              <b class="name">{{ invoice.recipientName }}</b>
              <div v-if="invoice.recipientAddress" v-html="formatAddress(invoice.recipientAddress)"></div>
              <div>{{ $t('account-number') }} : <b>{{ invoice.recipientAccountNumber }}</b></div>
              <div v-if="invoice.recipientRegistrationNumber">{{ $t('organization.registration-number') }} : <b>{{ invoice.recipientRegistrationNumber }}</b></div>
              <div v-if="invoice.recipientVatNumber">{{ $t('vat-number') }} : <b>{{ invoice.recipientVatNumber }}</b></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto" class="invoice-infos">
              <div v-html="$t('invoice.period-from-to', {start: formatUtcDate(invoice.startDate, $t('format_date')), end: formatUtcDate(invoice.endDate, $t('format_date')) }) "></div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="text-right align-end d-flex">
              <div>{{ $t('created-at')}} <b>{{ invoice.createdAt | formatDate($t('format_date')) }}</b></div>
            </v-col>
          </v-row>

         <v-row v-for="section in sections" :key="section.label">
            <v-col>
              <h4 class="mb-1">{{ section.label }}</h4>
              <v-simple-table class="invoice-lines">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="100" class="text-center">{{ $t('invoice.product-code') }}</th>
                      <th>{{ $t('label') }}</th>
                      <th width="40" class="text-center">{{ $t('invoice.quantity') }}</th>
                      <th width="100" class="text-right">{{ $t('invoice.unit-price') }}</th>
                      <th width="100" class="text-right">{{ $t('invoice.vat-rate') }}</th>
                      <th width="100" class="text-center">{{ $t('country') }}</th>
                      <th width="150" class="text-right">{{ $t('invoice.total-ht') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(type, t) in section.types">
                      <tr :key="section.label + '::' + t">
                        <td colspan="7" class="invoice-line-type">{{ $t('invoice.line-types.' + type.label) }}</td>
                      </tr>
                      <tr v-for="line in type.lines" :key="line.id">
                        <td class="text-center">{{ line.productCode }}</td>
                        <td>
                          <div><b>{{ line.label }}</b></div>
                          <div v-for="(descLine, i) in line.description?.split('\n')" :key="line.id + '::' + i">{{ descLine }}</div>
                        </td>
                        <td class="text-center">{{ line.quantity }}</td>
                        <td class="text-right">{{ numberFormat(line.unitPrice) }} €</td>
                        <td class="text-right">{{ !line.isDisbursement ? (line.selfLiquidation ? $t('invoice.self-liquidation') : line.vatRate + ' %') : '' }}</td>
                        <td class="text-center">{{ !line.isDisbursement ? line.country : '' }}</td>
                        <td class="text-right ">{{ numberFormat(line.netTotal) }} €</td>
                      </tr>
                    </template>

                    <tr class="total">
                      <td colspan="5" class="no-border"></td>
                      <td class="text-center">{{ $t('invoice.total-ht') }}</td>
                      <td class="text-right font-weight-bold">{{ numberFormat(section.total) }} €</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-simple-table class="invoice-bases">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th width="100" class="text-right">{{ $t('invoice.base-ht') }}</th>
                    <th width="100" class="text-right">{{ $t('invoice.vat-rate') }}</th>
                    <th width="100" class="text-center">{{ $t('country') }}</th>
                    <th width="150" class="text-right">{{ $t('invoice.vat-amount') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(base, k) in bases" :key="k">
                      <td class="text-right">{{ numberFormat(base.amount) }} €</td>
                      <td class="text-right">{{ base.label }}</td>
                      <td class="text-center">{{ base.country }}</td>
                      <td class="text-right">{{ base.selfLiquidation ? '-' : numberFormat(base.vatAmount) + ' €' }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td width="150" class="text-right">Montant HT</td>
                      <td width="150" class="text-right">{{ numberFormat(invoice.netTotal) }} €</td>
                    </tr>
                    <tr>
                      <td class="text-right">TVA</td>
                      <td class="text-right">{{ numberFormat(invoice.vatTotal) }} €</td>
                    </tr>
                    <tr>
                      <td class="text-right font-weight-bold">Montant TTC</td>
                      <td class="text-right font-weight-bold">{{ numberFormat(invoice.finalTotal) }} €</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-dialog max-width="400" v-model="sageInvoiceNumberDialog" scrollable>
            <v-form @submit.prevent="updateSageInvoiceNumber" v-if="invoice">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                          :label="$t('invoice.sage-invoice-number')"
                          v-model="sageInvoiceNumber"
                          hide-details
                          clearable
                      />
                    </v-col>
                    <v-col cols="auto" class="pt-5">
                      <v-btn small type="submit" color="primary">
                        {{ $t('update') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </v-dialog>

        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-row class="ma-0">
            <v-col cols="auto">
              <v-btn small @click="download" type="button">
                {{ $t('invoice.download-pdf') }}
              </v-btn>
              <v-btn class="ml-3" small @click="downloadAttachment" type="button">
                {{ $t('invoice.download-attachments') }}
              </v-btn>
              <v-btn class="ml-3" small @click="updateBillingDetails" type="button">
                {{ $t('invoice.update-billing-details') }}
              </v-btn>
              <v-btn class="ml-3" small @click="sageInvoiceNumberDialog = true" type="button">
                {{ $t('invoice.edit-sage-invoice-number') }}
              </v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn small class="primary" @click="dialog = false" type="button">
                {{ $t('close') }}
              </v-btn>
            </v-col>
          </v-row>

        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import InvoiceRepository from "@repository/InvoiceRepository";

export default {

  components: {

  },

  data() {
    return {
      invoiceId: null,
      dialog: false,
      sageInvoiceNumberDialog: false,
      sageInvoiceNumber: null,
      invoice: null,
    }
  },

  watch: {

  },

  computed: {
    sections() {
      const sections = {}
      for(let item of this.invoice.lines) {
        if(!sections[item.section]) {
          sections[item.section] = {
            label: item.section,
            types: {},
            total: 0,
          }
        }
        if(!sections[item.section].types[item.type]) {
          sections[item.section].types[item.type] = {
            label: item.type,
            lines: [],
            total: 0
          }
        }
        sections[item.section].total += item.netTotal
        sections[item.section].types[item.type].lines.push(item)
        sections[item.section].types[item.type].total += item.netTotal
      }


      const finalSections = []
      for(let section in sections) {

        const newSection = {
          label: sections[section].label,
          types: [],
          total: sections[section].total
        }

        for(const type in sections[section].types) {
          newSection.types.push(sections[section].types[type])
        }

        finalSections.push(newSection)
      }

      return finalSections
    },

    bases() {
      const bases = {}

      for(let item of this.invoice.lines) {
        if(item.isDisbursement) {
          continue
        }

        let b = item.vatRate
        if(item.selfLiquidation) {
          b = 'auto'
        }
        b += '_' + item.country

        if(!bases[b]) {
          bases[b] = {
            label: item.selfLiquidation ? this.$t('invoice.self-liquidation') : item.vatRate + '%',
            country: item.country,
            amount: 0,
            vatAmount: 0,
            selfLiquidation: item.selfLiquidation,
          }
        }
        bases[b].amount += item.netTotal
        bases[b].vatAmount += item.valueAddedTax
      }

      if(Object.keys(bases).length === 0) {
        return {
          "empty": {

          }
        }
      }

      return bases
    }
  },


  methods: {

    open(invoiceId) {
      this.invoiceId = invoiceId
      this.showLoading(true)
      this.loadData().then(() => {
        this.dialog = true
      })
    },

    loadData() {
      return new Promise((resolve, reject) => {
        this.showLoading(true)
        InvoiceRepository.get(this.invoiceId).then(invoice => {
          this.invoice = invoice
          this.sageInvoiceNumber = invoice.sageInvoiceNumber
          this.showLoading(false)
          resolve(invoice)
        }).catch(error => {
          this.notifyError(error)
          this.showLoading(false)
        })
      })
    },

    formatAddress(address) {
      if(!address) {
        return ''
      }

      const lines = []

      let str = ''
      if(address.number) {
        str += address.number + ', '
      }
      if(address.street) {
        str += address.street
      }
      if(str) {
        lines.push(str)
      }

      str = ''
      if(address.zipcode) {
        str += address.zipcode + ' '
      }
      if(address.city) {
        str += address.city
      }
      if(str) {
        lines.push(str)
      }

      if(address.country) {
        lines.push(address.country)
      }
      return lines.join('<br>')
    },

    download() {
      this.showLoading(true)
      InvoiceRepository.inlinePdf(this.invoice.id).then(url => {
        window.open(url, "_blank");
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    downloadAttachment() {
      this.showLoading(true)
      InvoiceRepository.downloadAttachment(this.invoice.id).then(url => {
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    updateBillingDetails() {
      this.showLoading(true)
      InvoiceRepository.updateBillingDetails(this.invoice.id).then(url => {
        this.loadData()
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    updateSageInvoiceNumber() {
      this.showLoading(true)
      InvoiceRepository.updateSageInvoiceNumber(this.invoice.id, this.sageInvoiceNumber).then(() => {
          this.notifySuccess(this.$t('update-success'));
          this.sageInvoiceNumberDialog = false
          this.invoice.sageInvoiceNumber = this.sageInvoiceNumber
          this.showLoading(false)
      }).catch(error => {
          this.notifyError(error)
          this.showLoading(false)
      })
    },
  }
}
</script>

<style lang="scss">
.invoice-show {
  .invoice-number {
    font-size:20px;
    font-weight: bold;
  }

  .invoice-address {
    min-width: 400px;
  }

  .v-data-table {
    tr {
      &:hover {
        background-color: transparent !important;
      }
      th {
        border-top: 1px solid #e0e0e0 !important;
        border-left: 1px solid #e0e0e0 !important;
        &:last-child {
          border-right: 1px solid #e0e0e0 !important;
        }
      }
      td {
        border-top: 1px solid #e0e0e0 !important;
        border-left: 1px solid #e0e0e0 !important;
        height: 30px !important;
        &.invoice-line-type {
          text-align: center;
          background: #eee;
        }
        &.no-border {
          border-left: 0 !important;
        }
        &:last-child {
          border-right: 1px solid #e0e0e0 !important;
        }
      }
      &:last-child {
        td:not(.no-border) {
          border-bottom: 1px solid #e0e0e0 !important;
        }
      }
    }
  }
}
</style>
