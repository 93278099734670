<template>
  <div class="pa-10">

    <title-page icon="mdi-file">
      {{ $tc('agreement.agreement', 2) }}
    </title-page>

    <v-row>
      <v-col cols="9">
        <v-text-field
            hide-details
            :label="$t('name')"
            readonly
            :value="`${agreement.name}`"
        />
      </v-col>
      <v-col>
        <v-text-field
            hide-details
            :label="$t('network')"
            readonly
            v-model="agreement.network"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            :label="$t('external-reference')"
            readonly
            hide-details
            :value="agreement.externalId"
        />
      </v-col>
      <v-col>
        <v-text-field
            :label="$t('agreement.contracted-at')"
            readonly
            hide-details
            :value="formatUtcDate(agreement.contractedAt)"
        />
      </v-col>
      <v-col>
        <v-text-field
            :label="$t('agreement.service-availability-date')"
            readonly
            hide-details
            :value="formatUtcDate(agreement.serviceAvailabilityDate)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            :label="$t('status')"
            readonly
            hide-details
            :value="agreement.status"
        />
      </v-col>
      <v-col>
        <v-text-field
            :label="$t('archived')"
            readonly
            hide-details
            :value="agreement.archived ? $t('yes') : $t('no')"
        />
      </v-col>
      <v-col>
        <v-text-field
            :label="$t('active')"
            readonly
            hide-details
            :value="agreement.active ? $t('yes') : $t('no')"
        />
      </v-col>
    </v-row>

    <v-row class="mt-16">
      <v-col >
        <headline>
          {{ $tc('agreement.operator') }}
        </headline>


        <v-row>
          <v-col>
            <v-text-field
                hide-details
                :label="$t('role')"
                readonly
                :value="agreement.operatorGroup?.role"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
                :label="$t('name')"
                readonly
                hide-details
                :value="agreement.operatorGroup?.name"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="caption mb-2">
              {{ $tc('operator.operator', agreement.operatorGroup?.operators?.length) }}
            </div>
            <v-chip v-for="operatorId in agreement.operatorGroup?.operators" :key="operatorId" dark :color="agreement.operatorGroup?.role === 'CPO' ? 'red' : 'blue'">
              {{ operatorId }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn small @click="openOperatorGroup(agreement.operatorGroup._id)">
              {{ $t('edit') }}
            </v-btn>
          </v-col>
        </v-row>


      </v-col>


      <v-col >

        <headline>
          {{ $tc('agreement.partner') }}
        </headline>

        <v-row>
          <v-col>
            <v-text-field
                hide-details
                :label="$t('role')"
                readonly
                :value="agreement.partnerOperatorGroup?.role"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
                :label="$t('name')"
                readonly
                hide-details
                :value="agreement.partnerOperatorGroup?.name"
            />
          </v-col>
        </v-row>



        <v-row>
          <v-col>
            <div class="caption mb-2">
              {{ $tc('operator.operator', 2) }}
            </div>
            <v-chip class="ml-2 mb-3" dark :color="agreement.partnerOperatorGroup?.role === 'CPO' ? 'red' : 'blue'" v-for="operatorId in agreement.partnerOperatorGroup?.operators" :key="operatorId">
              {{ operatorId }}
            </v-chip>
            <v-row>
              <v-col class="text-center">
                <v-btn small @click="openOperatorGroup(agreement.partnerOperatorGroup._id)">
                  {{ $t('edit') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>


      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="mb-6">
        <headline>
          {{ $t('agreement.issuer-of-invoices')}}
        </headline>
        <v-row v-for="detail in invoiceIssuer?.billingDetails" :key="detail.id">
          <v-col cols="3">
            <v-row>
              <v-col>
                <div>{{ detail.country ? detail.country : $t('billing-details.all-countries') }}</div>
                <div class="caption mt-6">{{ $tc('operator.operator', 2)}}</div>
                <v-chip class="mr-2 mt-2" dark :color="invoiceIssuer.role === 'CPO' ? 'red' : 'blue'" v-for="oId in operatorForCountry(invoiceIssuer, detail.country)" :key="oId">{{ oId }}</v-chip>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-card>
              <v-card-text>
                <billing-detail :value="detail" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-6">
        <headline>
          {{ $t('agreement.recipient-of-invoices')}}
        </headline>
        <v-row v-for="detail in invoiceRecipient?.billingDetails" :key="detail.id">
          <v-col cols="3">
            <v-row>
              <v-col>
                <div>{{ detail.country ? detail.country : $t('billing-details.all-countries') }}</div>
                <div class="caption mt-6">{{ $tc('operator.operator', 2)}}</div>
                <v-chip class="mr-2 mt-2" dark :color="invoiceRecipient.role === 'CPO' ? 'red' : 'blue'" v-for="oId in operatorForCountry(invoiceRecipient, detail.country)" :key="oId">{{ oId }}</v-chip>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-card>
              <v-card-text>
                <billing-detail :value="detail"></billing-detail>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>





  </div>
</template>

<script>
import AgreementRepository from "@repository/AgreementRepository";
import BillingDetail from "@/components/elements/BillingDetail.vue";

export default {
  components: {
    BillingDetail,

  },

  data() {
    return {
      agreement: {},
      addPartnerOperatorsModal: false,
      addPartnerOperators: null,
      billingDetails: []
    }
  },


  mounted() {
    if(this.isEdit) {
      this.loadData()
    }
  },

  computed: {

    id() {
      return this.$route.params.id ?? null
    },

    isEdit() {
      return !!this.id
    },

    invoiceIssuer() {
      return this.agreement.operatorGroup.role === 'CPO' ? this.agreement.operatorGroup : this.agreement.partnerOperatorGroup
    },

    invoiceRecipient() {
      return this.agreement.operatorGroup.role === 'eMSP' ? this.agreement.operatorGroup : this.agreement.partnerOperatorGroup
    }

  },

  methods: {

    loadData() {
      this.showLoading(true)
      AgreementRepository.get(this.id).then(response => {
        this.agreement = response
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    operatorForCountry(operatorGroup, country) {
      if(country) {
        return operatorGroup?.operators?.filter(operatorId => operatorId.startsWith(country))
      } else {
        let countries = {}
        for(let detail of operatorGroup.billingDetails ?? []) {
          if(detail.country) {
            countries[detail.country] = true
          }
        }
        let data = []
        for(let operatorId of operatorGroup.operators) {
          if(!countries[operatorId.substr(0, 2)]) {
              data.push(operatorId)
          }
        }
        return data
      }
    },

    openOperatorGroup(id) {
      window.open(this.$router.resolve({name: 'operator-group.edit', params: {id}}).href)
    },

  }
}
</script>

<style lang="scss">

</style>
