<template>
  <div class="pa-10">
    <title-page icon="ico-invoice">
      {{ $tc('invoice.invoice', 2) }}
    </title-page>



    <v-tabs v-model="recipientType" class="elevation-3">
      <v-tab tab-value="organization">
        {{ $t('organization.organizations')}}
      </v-tab>
      <v-tab tab-value="customer">
        {{ $tc('customer.customer', 2)}}
      </v-tab>
      <v-tab tab-value="member">
        {{ $tc('member.member', 2)}}
      </v-tab>
      <v-tab tab-value="agreement">
        {{ $tc('roaming', 2)}}
      </v-tab>
    </v-tabs>




    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <date-picker
                label="Date de création"
                v-model="rangeDate"
                clearable
                range
            />
          </v-col>
          <v-col>
            <date-picker
                label="Date de la période"
                v-model="rangePeriodDate"
                clearable
                range
            />
          </v-col>
        </v-row>
        <template v-if="recipientType === 'organization'">
          <v-row>
            <v-col>
              <organization-select
                  :label="$t('organization.organization')"
                  clearable
                  v-model="filters.organizationId"
              />
            </v-col>
            <v-col>
              <v-select
                  :label="$t('invoice.content-type')"
                  clearable
                  v-model="filters.contentType"
                  :items="[
                    {text: $tc('enums.invoice-content-type.subscription', 2), value: 'subscription'},
                    {text: $t('enums.invoice-content-type.usage'), value: 'usage'},
                  ]"
              />
            </v-col>
            <v-col v-if="filters.contentType === 'subscription'">
              <v-select
                  :label="$tc('subscription.subscription-plan')"
                  clearable
                  v-model="filters.subscriptionPlanId"
                  :items="subscriptionPlansTreated"
                  item-text="name"
                  item-value="id"
              />
            </v-col>

          </v-row>
        </template>

        <template v-if="recipientType === 'customer'">
          <v-row>
            <v-col>
              <customer-select
                  :label="$tc('customer.customer')"
                  clearable
                  v-model="filters.customerId"
              />
            </v-col>

          </v-row>
        </template>

        <template v-if="recipientType === 'agreement'">
          <v-row>
            <v-col>
              <v-autocomplete
                  :label="$tc('operator.operator')"
                  v-model="filters.operatorId"
                  :items="operators"
                  item-text="name"
                  item-value="id"
                  clearable
              >
                <template #item="{item}">
                  {{ item.name }}<template v-if="item.id !== item.name "> ({{ item.id }})</template>
                </template>

                <template #selection="{item}">
                  {{ item.name }}<template v-if="item.id !== item.name "> ({{ item.id }})</template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col>
              <v-autocomplete
                  :label="$tc('agreement.partner')"
                  v-model="filters.partnerOperators"
                  :items="partners"
                  item-text="name"
                  item-value="id"
                  clearable
              >
                <template #item="{item}">
                  {{ item.name }}<template v-if="item.id !== item.name "> ({{ item.id }})</template>
                </template>

                <template #selection="{item}">
                  {{ item.name }}<template v-if="item.id !== item.name "> ({{ item.id }})</template>
                </template>
              </v-autocomplete>
            </v-col>

          </v-row>
        </template>
      </v-card-text>
    </v-card>




    <v-divider class="my-6"/>

    <v-card class="my-3" color="primary">
      <v-card-text class=" white--text" style="font-size: 20px;">
        <v-row>
          <v-col>
            <v-row>
              <v-col>Nb de factures</v-col>
              <v-col class="text-right"><b>{{ stats.total }}</b></v-col>
            </v-row>
          </v-col>
          <v-divider inset vertical />
          <v-col>
            <v-row>
              <v-col>Total HT</v-col>
              <v-col class="text-right"><b>{{ numberFormat(stats.netTotal) }} €</b></v-col>
            </v-row>
          </v-col>
          <v-divider inset vertical />
          <v-col>
            <v-row>
              <v-col>Total TTC</v-col>
              <v-col class="text-right"><b>{{ numberFormat(stats.finalTotal) }} €</b></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-divider class="my-6"/>

    <v-row>
      <v-col >
        <v-data-table
          :headers="headers"
          :items="invoices"
          :options.sync="options"
          :loading="loading"
          :server-items-length="total"
        >
          <template #item.createdAt="{item}">
            {{ item.createdAt | formatDate($t('format_date')) }}
          </template>
          <template #item.startDate="{item}">
            {{ formatUtcDate(item.startDate, $t('format_date')) }} - {{ formatUtcDate(item.endDate, $t('format_date')) }}
          </template>

          <template #item.netTotal="{item}">
            {{ item.netTotal ? numberFormat(item.netTotal) + ' €' : '-' }}
          </template>

          <template #item.status="{item}">
            {{ $t('invoice.statues.' + item.status) }}
          </template>

          <template #item.invoiceContentType="{item}">
            {{ $t('enums.invoice-content-type.' + item.invoiceContentType) }}
          </template>

          <template #item.options="{item}">
            <v-btn icon small @click="openInvoice(item)">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn small @click="downloadCsv">
          <v-icon left>mdi-download</v-icon>
          {{ $t('export') }}
        </v-btn>
      </v-col>
    </v-row>


    <invoice-show-modal ref="invoiceModal" />

  </div>
</template>

<script>
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";
import InvoiceRepository from "@repository/InvoiceRepository";
import InvoiceShowModal from "@modals/InvoiceShowModal.vue";
import CustomerSelect from "@blocks/Select/CustomerSelect.vue";
import OperatorRepository from "@repository/OperatorRepository";
import SubscriptionRepository from "@repository/SubscriptionRepository";

export default {
  components: {
    OrganizationSelect,
    CustomerSelect,
    InvoiceShowModal,
  },
  data() {
    return {
      loading: false,
      recipientType: 'organization',
      allOperators: [],
      rangeDate: [],
      rangePeriodDate: [],
      stats: {
        total: 0,
        netTotal: 0,
        finalTotal: 0,
      },
      filters: {
        organizationId: null,
        contentType: null,
        customerId: null,
        operatorId: null,
        partnerOperators: null,
      },
      total: 0,
      invoices: [],
      subscriptionPlans: [],
      options: null,
    }
  },

  mounted() {
    this.loadOperators()
    this.loadSubscriptionPlans()
  },

  watch: {
    filters: {
      handler() {
        if(this.options.page > 1) {
          this.options.page = 1
        } else {
          this.loadData()
        }
      },
      deep: true
    },

    rangePeriodDate() {
      this.loadData()
    },

    rangeDate() {
      this.loadData()
    },

    options() {
      this.loadData()
    },

    recipientType() {
      this.showLoading(true)
      this.filters = {
        organizationId: null,
        contentType: null,
        customerId: null,
        operatorId: null,
        partnerOperators: null,
      }
    },



  },

  computed: {

    headers() {
      return [
        {text: this.$t('created-at'), value: 'createdAt'},
        {text: this.$t('invoice.reference'), value: 'invoiceReference'},
        {text: this.$t('period'), value: 'startDate'},
        // {text: this.$t('invoice.recipient-type'), value: 'recipientType'},
        {text: this.$t('invoice.content-type'), value: 'invoiceContentType'},
        {text: this.$t('invoice.issuer'), value: 'issuerName'},
        {text: this.$t('invoice.recipient'), value: 'recipientName'},
        {text: this.$t('price-ht'), value: 'netTotal'},
        {text: this.$t('status'), value: 'status'},
        {value: 'options'},
      ]
    },

    subscriptionPlansTreated() {
      return this.subscriptionPlans.map(plan => {
        return {
          id: plan.id,
          // name: this.localizedString(plan.label)
          name: plan.name
        }
      })
    },


    operators() {
      return this.allOperators.filter(partner => partner.ocpi)
    },

    partners() {
      return this.allOperators.filter(partner => !partner.ocpi)
    },

  },

  methods: {

    loadData() {

      this.loading = true
      let data = {
        recipientType: this.recipientType,
        ...this.filters,
      }


      if(this.rangeDate?.length > 0) {
        data.startDate = this.rangeDate[0]
        data.endDate = this.rangeDate[1]
      }

      if(this.rangePeriodDate?.length > 0) {
        data.startPeriodDate = this.rangePeriodDate[0]
        data.endPeriodDate = this.rangePeriodDate[1]
      }


      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      if (this.options.sortBy.length > 0) {
        data.sortBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc'
      }


      InvoiceRepository.search(data).then(response => {
        this.stats = response.stats
        this.total = response.total
        this.invoices = response.items
        this.loading = false
        this.showLoading(false)
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        this.loading = false
        this.showLoading(false)
      })
    },

    loadSubscriptionPlans() {
      SubscriptionRepository.searchPlans({limit: 999}).then(result => {
        this.subscriptionPlans = result.items
      }).catch(err => {
        this.notifyError(err)
      })
    },

    openInvoice(invoice) {
      console.log(invoice)
      this.$refs.invoiceModal.open(invoice.id)
    },

    loadOperators() {
      OperatorRepository.search().then(operators => {
        this.allOperators = operators
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
      })
    },

    downloadCsv() {
      this.showLoading(true)

      let data = {
        recipientType: this.recipientType,
        ...this.filters,
      }


      if(this.rangeDate?.length > 0) {
        data.startDate = this.rangeDate[0]
        data.endDate = this.rangeDate[1]
      }

      if(this.rangePeriodDate?.length > 0) {
        data.startPeriodDate = this.rangePeriodDate[0]
        data.endPeriodDate = this.rangePeriodDate[1]
      }

      InvoiceRepository.exportCsv(data).then(() => {
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    downloadPdf(invoice) {
      this.showLoading(true)
      InvoiceRepository.pdf(invoice.id).then(() => {
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    downloadAttachment(invoice) {
      this.showLoading(true)
      InvoiceRepository.downloadAttachment(invoice.id).then(() => {
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },
  },

}
</script>

<style lang="scss">


</style>
