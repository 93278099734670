<template>
  <div class="pa-10">

    <title-page icon="mdi-file">
      {{ $tc('agreement.agreement', 2) }}
    </title-page>



    <v-row class="mt-16" justify="end">
      <v-col cols="auto">
        <v-btn small @click="importGireveAgreementModal = true">
          {{ $t('agreement.gireve-import')}}
        </v-btn>
      </v-col>
    </v-row>


    <v-row>
      <v-col>
        <v-text-field
            v-model="filters.search"
            :label="$t('search')"
            clearable
        />
      </v-col>
      <v-col>
        <v-select
            v-model="filters.operatorRole"
            :label="$t('role')"
            clearable
            :items="[
                {text: $t('operator.is-cpo'), value: 'CPO'},
                {text: $t('operator.is-emp'), value: 'eMSP'}
            ]">
        </v-select>
      </v-col>
      <v-col>
        <v-select
            v-model="filters.active"
            :label="$t('state')"
            clearable
            :items="[
                {text: $t('active'), value: true},
                {text: $t('inactive'), value: false}
            ]">
        </v-select>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="agreements"
          :server-items-length="total"
          :options.sync="options"
        >



          <template #item.externalId="{item}">
            <router-link :to="{name: 'agreements.edit', params: {id: item._id}}">
              {{ item.externalId }}
            </router-link>
          </template>

          <template #item.active="{item}">
            <v-icon color="green" v-if="item.active">mdi-check</v-icon>
            <v-icon color="red" v-else>mdi-close</v-icon>
          </template>

          <template #item.operatorGroup.operators="{item}">
            <v-chip small class="mb-2 mr-2" dark :color="item.operatorGroup.role === 'CPO' ? 'red' : 'blue'" :key="operator" v-for="operator in item.operatorGroup.operators">
              {{ operator }}
            </v-chip>
          </template>

          <template #item.partnerOperatorGroup.operators="{item}">
            <v-chip small class="mb-2 mr-2" dark :color="item.partnerOperatorGroup.role === 'CPO' ? 'red' : 'blue'" :key="operator" v-for="operator in item.partnerOperatorGroup.operators">
              {{ operator }}
            </v-chip>
          </template>

          <template #item.partnerName="{item}">
           {{ item.partnerName }} ({{ item.partnerType }})
          </template>


        </v-data-table>
      </v-col>
    </v-row>


    <v-dialog v-model="importGireveAgreementModal" max-width="800">
      <v-card>
        <v-card-title>
          {{ $t('agreement.gireve-import')}}
        </v-card-title>
        <v-card-text>
          <v-file-input
              v-model="gireveImportFile"
              :label="$t('select-file')"
              accept="text/csv"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col class="text-right">
              <v-btn small @click="importGireveAgreementModal = false">
                {{ $t('close') }}
              </v-btn>
              <v-btn class="ml-3" small color="primary" @click="importGireve">
                {{ $t('agreement.gireve-import')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>




  </div>
</template>

<script>
import AgreementRepository from "@repository/AgreementRepository";

export default {
  data() {
    return {
      filters: {
        search: null,
        operatorRole: null,
        active: true,
      },
      importGireveAgreementModal: false,
      agreements: [],
      total: 0,
      gireveImportFile: null,
      options:  null,
    }
  },

  watch: {
    options: {
      handler() {
        this.loadData()
      },
      deep: true
    },
    filters : {
      handler() {
        if(this.options.page > 1) {
          this.options.page = 1
        } else {
          this.loadData()
        }
      },
      deep: true
    },
  },

  computed: {
    headers() {
      return [
        {text: this.$t('agreement.offer-id'), value: 'externalId', align: 'left', sortable: false},
        {text: this.$tc('name'), value: 'name'},
        {text: this.$t('role'), value: 'operatorGroup.role'},
        {text: this.$tc('operator.operator'), value: 'operatorGroup.name'},
        {text: this.$tc('operator.operator'), value: 'operatorGroup.operators'},
        {text: this.$tc('agreement.partner'), value: 'partnerOperatorGroup.name'},
        {text: this.$tc('agreement.partner'), value: 'partnerOperatorGroup.operators'},
        {text: this.$t('status'), value: 'status'},
        {text: this.$t('active'), value: 'active'},
      ]
    },
  },

  methods: {

    loadData() {
      this.showLoading(true)
      const data = {
        ...this.filters
      }

      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage


      if (this.options.sortBy.length > 0) {
        data.sortBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc'
      }

      AgreementRepository.search(data).then(data => {
        this.total = data.total
        this.agreements = data.items
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },


    importGireve() {
      if(this.gireveImportFile) {
        this.showLoading(true)
        AgreementRepository.uploadGireve(this.gireveImportFile).then(response => {
          this.notifySuccess('Importation réussie')
          this.showLoading(false)
          this.loadData()
          this.importGireveAgreementModal = false
        }).catch(error => {
          this.notifyError(error)
          this.showLoading(false)
        })
      }
    },

  }
}
</script>

<style lang="scss">

</style>
