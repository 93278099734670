var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.stats)?_c('div',{staticClass:"pa-10"},[_c('title-page',{attrs:{"icon":"mdi-file-chart-outline"}},[_vm._v(" "+_vm._s(_vm.$t('reports.financial.dashboard'))+" ")]),_c('v-breadcrumbs',{attrs:{"items":[
          {text: _vm.$t('reports.reports'), to: {name: 'reports'}, exact: true},
          {text:  _vm.$t('reports.financial.dashboard')},
      ],"divider":">"}}),_c('v-row',[_c('v-col',[_c('date-picker',{attrs:{"label":_vm.$t('period'),"type":"month","clearable":""},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}})],1)],1),_c('headline',[_vm._v("Licences")]),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"fill-height"},[_c('v-card-text',[_c('v-row',[_c('v-col',[_vm._v(" Nombre d'abonnement actif : "),_c('b',[_vm._v(_vm._s(_vm.stats.subscriptionsCount))])])],1),_c('v-row',{staticClass:"mt-6",attrs:{"dense":""}},[_c('v-col',[_vm._v(" Il y a "),_c('b',[_vm._v(_vm._s(_vm.stats.licencesStats.count))]),_vm._v(" licences active pour un montant de "),_c('b',[_vm._v(_vm._s(_vm.numberFormat(_vm.stats.licencesStats.price))+" €")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_vm._v(" Il y a "),_c('b',[_vm._v(_vm._s(_vm.stats.licencesStats.future.count))]),_vm._v(" licences future pour un montant de "),_c('b',[_vm._v(_vm._s(_vm.numberFormat(_vm.stats.licencesStats.future.price))+" €")])])],1)],1)],1)],1),_c('v-col',[_c('v-card',{staticClass:"fill-height"},[_c('v-card-text',[_c('pie-graph',{attrs:{"data":_vm.globalLicencePie,"label":"Licences active"}})],1)],1)],1),_c('v-col',[_c('v-card',{staticClass:"fill-height"},[_c('v-card-text',[_c('pie-graph',{attrs:{"data":_vm.licenceByTypePie}})],1)],1)],1)],1),_c('headline',{staticClass:"mt-16"},[_vm._v("Charges")]),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"fill-height",attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","hide-default-header":"","hide-default-footer":"","headers":[
              {value: 'text', align: 'left'},
              {value: 'value', align: 'right'},
            ],"items":_vm.formatDataTable(_vm.stats.cdrStats)},scopedSlots:_vm._u([{key:"item.value",fn:function({value}){return [_c('b',[_vm._v(_vm._s(value))])]}}],null,false,3847055935)})],1)],1)],1),_c('v-col',[_c('v-card',{staticClass:"fill-height"},[_c('v-card-text',[_c('pie-graph',{attrs:{"data":_vm.nbChargeByTypes,"label":"Type de charges"}})],1)],1)],1),_c('v-col',[_c('v-card',{staticClass:"fill-height"},[_c('v-card-text',[_c('pie-graph',{attrs:{"data":_vm.cpoPriceByTypes}})],1)],1)],1)],1),_c('v-row',_vm._l((_vm.stats.cdrStats.byTypes),function(item,k){return _c('v-col',{key:k,attrs:{"cols":"4"}},[_c('v-card',{staticClass:"fill-height"},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","hide-default-header":"","hide-default-footer":"","headers":[
              {value: 'text', align: 'left'},
              {value: 'value', align: 'right'},
            ],"items":_vm.formatDataTable(item)},scopedSlots:_vm._u([{key:"item.value",fn:function({value}){return [_c('b',[_vm._v(_vm._s(value))])]}}],null,true)}),_vm._v(" "+_vm._s(k)+" ")],1)],1)],1)}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }