import Vue from 'vue'

class FinancialRepository {

  dashboard(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/financial/dashboard',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


}

export default new FinancialRepository()