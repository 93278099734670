import Vue from 'vue'

class AgreementRepository {

  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/agreements/search',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/agreements/get/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  create(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/agreements/create',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  sendPartnerOperators(id, operators) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/agreements/send-partner-operators/' + id,
        method: 'post',
        data: {operators},
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  updateBillingDetails(id, billingDetails) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/agreements/update-billing-details/' + id,
        method: 'post',
        data: {billingDetails},
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  uploadGireve(file) {

    const data = new FormData()
    data.set('file', file)

    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/agreements/import/gireve',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


}

export default new AgreementRepository()
