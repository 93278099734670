import Vue from 'vue'

class BillingRequestRepository {

  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/search',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/get/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  pdf(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/billing-requests/pdf/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  inlinePdf(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/pdf/' + id,
        method: 'get',
        responseType: 'arraybuffer'
      })
      .then(response => {
        let blob = new Blob([response.data], { type: response.headers['content-type'] } );
        let url = window.URL.createObjectURL(blob);
        resolve(url)
      }).catch(async err => {
        let blob = new Blob([err.response.data], { type: err.response.headers['content-type'] } );
        err.response.data = JSON.parse(await blob.text())
        reject(err)
      })
    })
  }


  exportCsv(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/billing-requests/export',
        method: 'post',
        data,
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  downloadAttachment(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/billing-requests/attachments/' + id,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateBillingDetails(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/update-billing-details/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  createBillingDetailInvoice(organizationId, billingRequests, invoiceFile) {

    const data = new FormData()
    data.append('content', JSON.stringify({organizationId, billingRequests}))
    data.append('invoiceFile', invoiceFile)

    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/create-invoice',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


}

export default new BillingRequestRepository()
