<template>
  <div class="pa-10" v-if="stats">
    <title-page icon="mdi-file-chart-outline">
      {{ $t('reports.financial.dashboard') }}
    </title-page>

    <v-breadcrumbs
        :items="[
            {text: $t('reports.reports'), to: {name: 'reports'}, exact: true},
            {text:  $t('reports.financial.dashboard')},
        ]"
        divider=">"
    ></v-breadcrumbs>


    <v-row>
      <v-col>
        <date-picker
            :label="$t('period')"
            type="month"
            v-model="selectedMonth"
            clearable
        />
      </v-col>
    </v-row>



    <headline>Licences</headline>
    <v-row>
      <v-col>
        <v-card class="fill-height">
          <v-card-text>
            <v-row>
              <v-col>
                Nombre d'abonnement actif : <b>{{ stats.subscriptionsCount }}</b>
              </v-col>
            </v-row>
            <v-row dense class="mt-6">
              <v-col>
                Il y a <b>{{ stats.licencesStats.count }}</b> licences active pour un montant de <b>{{ numberFormat(stats.licencesStats.price) }} €</b>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                Il y a <b>{{ stats.licencesStats.future.count }}</b> licences future pour un montant de <b>{{  numberFormat(stats.licencesStats.future.price) }} €</b>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="fill-height">
          <v-card-text>
            <pie-graph :data="globalLicencePie" label="Licences active"></pie-graph>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="fill-height">
          <v-card-text>
            <pie-graph :data="licenceByTypePie"></pie-graph>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <headline class="mt-16">Charges</headline>
    <v-row>
      <v-col>
        <v-card class="fill-height" color="primary" dark>
          <v-card-text>
            <v-data-table
                dense
                hide-default-header
                hide-default-footer
                :headers="[
                {value: 'text', align: 'left'},
                {value: 'value', align: 'right'},
              ]"
                :items="formatDataTable(stats.cdrStats)"
            >
              <template #item.value="{value}">
                <b>{{ value }}</b>
              </template>
            </v-data-table>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="fill-height">
          <v-card-text>
            <pie-graph :data="nbChargeByTypes" label="Type de charges"></pie-graph>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="fill-height">
          <v-card-text>
            <pie-graph :data="cpoPriceByTypes"></pie-graph>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" v-for="(item, k) in stats.cdrStats.byTypes" :key="k">
        <v-card class="fill-height">
          <v-card-text>
            <v-data-table
                dense
              hide-default-header
              hide-default-footer
              :headers="[
                {value: 'text', align: 'left'},
                {value: 'value', align: 'right'},
              ]"
              :items="formatDataTable(item)"
            >
              <template #item.value="{value}">
                <b>{{ value }}</b>
              </template>
            </v-data-table>
            {{  k }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>





  </div>
</template>

<script>
import FinancialRepository from "@repository/FinancialRepository";
import PieGraph from "@pages/Report/graph/PieGraph.vue";
import dayjs from "dayjs";

export default {
  components: {
    PieGraph,
  },

  data() {
    return {
      stats: null,
      selectedMonth: dayjs().format('YYYY-MM'),
    }
  },

  mounted() {
    this.loadData()
  },

  watch: {
    selectedMonth() {
      this.loadData()
    }
  },

  computed: {
    globalLicencePie() {
      return {
        series: [{
          name: 'Nb Licences',
          colorByPoint: true,
          data: [
            {
              name: 'Active',
              y: this.stats.licencesStats.current.count,
              selected: true,
              color: '#e53c23'
            },
            {
              name: 'Future',
              sliced: true,
              y: this.stats.licencesStats.future.count,
              color: '#6c6c6c'
            },
          ]
        }]
      }
    },

    licenceByTypePie() {
      const data = Object.keys(this.stats.licencesStats.byTypes).map(key => {
        return {
          name: this.$t('enums.subscription-plan-types.' + key),
          y: this.stats.licencesStats.byTypes[key].count
        }
      })
      return {
        series: [{
          name: 'Nb Licences',
          colorByPoint: true,
          data,
        }]
      }
    },

    nbChargeByTypes() {
      const data = Object.keys(this.stats.cdrStats.byTypes).map(key => {
        return {
          name: key,
          y: this.stats.cdrStats.byTypes[key].count
        }
      })
      return {
        series: [{
          name: 'Nb de charges',
          colorByPoint: true,
          data,
        }]
      }
    },

    cpoPriceByTypes() {
      const data = Object.keys(this.stats.cdrStats.byTypes).map(key => {
        return {
          name: key,
          y: Number(this.stats.cdrStats.byTypes[key].cpoPrice.toFixed(2)),
          sliced: true,
        }
      })
      return {
        tooltipValueSuffix: ' €',
        series: [{
          name: 'Volume d\'affaire',
          colorByPoint: true,
          data,
        }]
      }
    }
  },

  methods: {
    loadData() {
      this.showLoading(true)
      const data = {}
      if (this.selectedMonth) {
        data.fromDate = this.selectedMonth
      }
      FinancialRepository.dashboard(data).then(stats => {
        this.stats = stats
        this.showLoading(false)
      }).catch(err => {
        this.notifyError(err)
        this.showLoading(false)
      })
    },


    formatDataTable(data) {

      return [
        {text: 'Nombre de charge effectuée', value: this.numberFormat(data.count, 0)},
        {text: 'Energies consommées', value: this.numberFormat(data.energy, 0) + ' kWh'},
        {text: 'Part CPO', value: this.numberFormat(data.cpoPrice) + ' €'},
        {text: 'Marge eMSP FR*ZMS', value: this.numberFormat(data.emspMargin) + ' €'},
        {text: 'Part itinérance (Gireve)', value: this.numberFormat(data.roamingCharges) + ' €'},
      ]
    }
  }
}
</script>

<style lang="scss">

</style>