import Vue from 'vue'

class InvoiceRepository {

  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/invoices/search',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/invoices/get/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  pdf(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/invoices/pdf/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  inlinePdf(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/invoices/pdf/' + id,
        method: 'get',
        responseType: 'arraybuffer'
      })
      .then(response => {
        let blob = new Blob([response.data], { type: response.headers['content-type'] } );
        let url = window.URL.createObjectURL(blob);
        resolve(url)
      }).catch(async err => {
        let blob = new Blob([err.response.data], { type: err.response.headers['content-type'] } );
        err.response.data = JSON.parse(await blob.text())
        reject(err)
      })
    })
  }

  exportCsv(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/invoices/export',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  downloadAttachment(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/invoices/attachments/' + id,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateBillingDetails(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/invoices/update-billing-details/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateSageInvoiceNumber(id, sageInvoiceNumber) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/invoices/update-sage-invoice-number/' + id,
        method: 'post',
        data: {
          sageInvoiceNumber,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

}

export default new InvoiceRepository()
